html{
  background-color: white !important;
  background-attachment: fixed;
  background-position: 75% 50%;
  background-image: url('../public/img/14.avif') !important;
}

body{
  
 
  background-color: transparent !important;
  
  
}
.cont{
  width: 100% !important;
  max-width: 33rem !important;
  padding: 0 1rem;
}

.App {
  text-align: center;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}

/* Media queries para diferentes tamaños de pantalla */
@media screen and (max-width: 768px) {
  .navbar {
    padding: 0.5rem;
  }
  
  .menu-horizontal {
    flex-direction: column;
    align-items: center;
  }
  
  .menu-horizontal li {
    margin: 0.5rem 0;
  }
}

@media screen and (max-width: 480px) {
  html {
    font-size: 14px;
  }
  
  .cont {
    padding: 0 0.5rem;
  }
}

/* Ajustes adicionales para responsividad */
.hero-content {
  padding: 1rem;
}

@media screen and (max-width: 768px) {
  .hero-content {
    padding: 1.5rem;
  }

  .card {
    margin: 1rem auto;
  }

  .card img {
    max-height: 200px;
    object-fit: cover;
  }

  .text-5xl {
    font-size: 2rem;
    line-height: 1.2;
  }
}

@media screen and (max-width: 480px) {
  .hero-content {
    padding: 1rem;
  }

  .card {
    margin: 0.5rem auto;
  }

  .text-5xl {
    font-size: 1.75rem;
  }

  .py-6 {
    padding-top: 1rem;
    padding-bottom: 1rem;
  }
}

/* Ajustes para mejorar el espaciado en dispositivos móviles */
.container {
  padding-left: 1rem;
  padding-right: 1rem;
  margin: 0 auto;
}

@media screen and (min-width: 768px) {
  .container {
    padding-left: 2rem;
    padding-right: 2rem;
  }
}

/* Mejoras en la navegación móvil */
.navbar {
  /* position: sticky; */
  top: 0;
  z-index: 50;
}

/* Estilos responsivos para la sección Nosotros */
.fondito2 {
  background-color: rgba(255, 255, 255, 0.9);
  width: 100%;
  height: 100%;
}

.icon-text {
  background-color: rgba(255, 255, 255, 0.95);
  border-radius: 0.5rem;
  transition: all 0.3s ease;
}

.icon-text:hover {
  transform: translateY(-2px);
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
}

.fondo-icon {
  background-color: #f8f9fa;
  border-radius: 50%;
  padding: 1rem;
  display: flex;
  align-items: center;
  justify-content: center;
  transition: all 0.3s ease;
}

.iconos {
  width: 100%;
  height: 100%;
  object-fit: contain;
}

/* Media queries para la sección Nosotros */
@media screen and (max-width: 768px) {
  .icon-text {
    padding: 1.5rem;
    text-align: center;
  }

  .fondo-icon {
    margin: 0 auto;
    width: 80px;
    height: 80px;
  }

  .icon-text h2 {
    margin-top: 1rem;
  }
}

@media screen and (max-width: 480px) {
  .icon-text {
    padding: 1rem;
  }

  .fondo-icon {
    width: 64px;
    height: 64px;
  }

  .icon-text h2 {
    font-size: 1.25rem;
  }

  .icon-text p {
    font-size: 0.875rem;
  }
}

/* Animaciones para la sección Nosotros */
.fade-in-on-scroll {
  opacity: 0;
  transform: translateY(20px);
  transition: opacity 0.6s ease-out, transform 0.6s ease-out;
}

/* Animación fade-in */
@keyframes fadeIn {
  from {
      opacity: 0;
  }
  to {
      opacity: 1;
  }
}

/* Clase para aplicar fade-in */
.fade-in {
  animation: fadeIn 0.6s ease-in-out;
}

.fade-in-on-scroll.visible {
  opacity: 1;
  transform: translateY(0);
}

/* Estilos base para todas las secciones */
section {
  position: relative;
  width: 100%;
  overflow: hidden;
}

/* Estilos para el Hero principal */
.hero-section {
  position: relative;
  height: 100vh;
}

.bg-hero-pattern {
  background-image: url('../public/img/8.webp');
  background-size: cover;
  background-position: center;
  background-repeat: no-repeat;
}

.overlay {
  background: #070707a4; /*#8b88889e*/
  display: flex;
  align-items: center;
  color: white;
}

.content-wrapper {
  width: 100%;
  max-width: 1200px;
  margin: 0 auto;
}

/* Estilos para la sección de servicios */
.services-section {
  
  backdrop-filter: blur(10px) !important;
  background-color:   transparent!important;
  color: #070707a4;
  padding: 4rem 0;
}
.info1-section {
  background-color:   #02315C!important;
  padding: 4rem 0;
}
.info3-section {
  background-color:   #02315C!important;
  padding: 4rem 0;
}

.services-section .card {
  height: 100%;
  transition: transform 0.3s ease;
}

.services-section .card:hover {
  transform: translateY(-5px);
}

/* Ajustes de espaciado entre secciones */
section + section {
  margin-top: 0;
}

/* Media queries para ajustes responsivos */
@media screen and (max-width: 768px) {
  .hero-section {
    height: auto;
    min-height: 100vh;
  }

  .content-wrapper {
    padding: 2rem 1rem;
  }

  .services-section {
    padding: 3rem 0;
  }
}

@media screen and (max-width: 480px) {
  .content-wrapper {
    padding: 1.5rem 1rem;
  }

  .services-section {
    padding: 2rem 0;
  }
}

/* Estilos para la sección About */
.about-section {
  background-color: #C6DCC5;
  position: relative;
}



.feature-card {
  background: white;
  border-radius: 1rem;
  overflow: hidden;
  transition: all 0.3s ease;
}

.feature-card:hover {
  transform: translateY(-5px);
}

.icon-wrapper {
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 50%;
  transition: all 0.3s ease;
}

.feature-card:hover .icon-wrapper {
  transform: scale(1.1);
}

/* Media queries para la sección About */
@media screen and (max-width: 768px) {
  .about-section {
    padding: 3rem 0;
  }

  .feature-card {
    padding: 1.5rem;
  }
}

@media screen and (max-width: 480px) {
  .about-section {
    padding: 2rem 0;
  }

  .feature-card {
    padding: 1rem;
  }

  .icon-wrapper {
    padding: 0.75rem;
  }
}

/* Estilos para el header fijo */
.fixed-header {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  z-index: 1000;
}

.navbar {
  -webkit-box-shadow: -2px 10px 26px -16px rgba(0,0,0,0.75);
-moz-box-shadow: -2px 10px 26px -16px rgba(0,0,0,0.75);
box-shadow: -2px 10px 26px -16px rgba(0,0,0,0.75);
  backdrop-filter: blur(10px);
  background-color: transparent !important;
  transition: all 0.3s ease;
}

/* Ajuste para el contenido debajo del navbar fijo 
section:first-of-type {
  padding-top: 4rem;
}*/

/* Estilos para el menú móvil */
.navbar .menu-vertical {
  background-color: rgba(255, 255, 255, 0.98);
  border-top: 1px solid rgba(0, 0, 0, 0.1);
}

/* Efectos hover para los enlaces del menú */
.navbar .menu li > *:hover {
  background-color: rgba(0, 0, 0, 0.05);
}

/* Media queries para el navbar */
@media screen and (max-width: 768px) {
  .navbar {
    padding: 0.5rem 1rem;
  }

  .navbar .menu-vertical {
    max-height: calc(100vh - 4rem);
    overflow-y: auto;
  }
}

/* Ajustes adicionales para el contenido */
main {
  margin-top: 4rem; /* Altura del navbar */
}

/* Animación para el navbar al hacer scroll */
.navbar.scrolled {
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
}
