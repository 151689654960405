@tailwind base;
@tailwind components;
@tailwind utilities;
.img-c{
  width: 17px !important;
  height: auto !important;
  border-radius: 1px !important;
}


.h1-info4{
  text-align:left;
  margin-left:11rem;
}
.margin-iz{
      margin-left: 8rem;
    margin-right: -5rem;
}
.img-fn{
  justify-self: center;
  width: 28rem;
  height: auto;
}

.bg-no{
  background-color: transparent !important; 
}
.redondo{
  margin-right: 1rem !important;
    border-radius: 70px !important;
    width: 22px;
    height: auto;
  
}



.tarjeta-vd{
  background-color: #E8F3E8 !important;
}
.titulo{
  font-size: 1.5rem;
  white-space: pre;
}


img {
  border-radius: 12px;
}

.card-title{
  margin-bottom: 1rem;
}
.colorable-icon{
  color: #609DFF;
  height: 40px !important;
  fill: #609DFF;
}

.colorable-icon2{
  color: #609DFF;
  height: 23px !important;
  fill: #609DFF;
}
/* .card2{
  max-width: 20rem ;
  
} */

ul {
  list-style-type: disc; /* Estilo de lista con puntos */
  margin-left: 20px; /* Ajuste de margen para la indentación */
}
.li2 {
  
  font-size: 1.2rem !important;
  color: #3B4540;
margin-bottom: 5px; /* Espaciado entre los elementos (opcional) */
}

li {
  font-family: 'Nobile', sans-serif ;
    font-size: 1.2rem ;
  margin-bottom: 5px; /* Espaciado entre los elementos (opcional) */
}

.nobile{
  
    font-family: 'Nobile', sans-serif !important;
    font-size: 1.5rem !important;


}
.GEI{
  display: flex;
    justify-content: center;
    margin-bottom: 1rem;
    font-size: 9rem;
    font-weight: 700;
}
*{
  font-family: 'Arial', sans-serif;

}
div {
  font-family: 'Arial', sans-serif;
}

.serv{
  align-items:baseline;
  margin-left:15rem;
}

 @media only screen and (max-width: 1280px) and (min-width: 801px){
  
  .ge{
    grid-template-columns: 1fr 1fr !important; /* Define dos columnas iguales */
  }
}
.h1-info1{
  text-align:left;
  margin-left:4rem;
  padding-top:3rem;
}
/* Media query para móviles */
@media only screen and (max-width: 800px){

  .GEI{
      font-size: 6rem;
      
  }


  .card-title {
    line-height: 3rem;
}
  .h1-info1{
    text-align:center;
    margin-left:0rem;
    padding-top:3rem;
  }
  .margin-iz{
    margin-left: 0px !important;
    margin-right: 0px !important;
  }

  .ge{
    grid-template-columns: 1fr !important; /* Define dos columnas iguales */
  }
  .titulo{
    white-space: break-spaces !important;
  }
  .div-info4{
    margin-left: 0 !important;
    width: 100%;
  }
  .h1-info4{
    margin-left: 0 !important;
  }
.serv {
  margin-left: 0rem !important;
}
  .colorable-icon {
    justify-self: center;
}

  .li2{
    font-size: 2.2rem !important;
  }

  .card2 {
    
    justify-self: center !important;
}



  .piel2 {
    font-size: 2.2rem !important;
    text-align: center !important;
   
}
.piel {
  text-align: center !important;
  justify-content: center !important;
  font-size: 2.8rem !important;
 
}



  .div-btn-hero{
    display: block !important;
  }
  .btn-contacto{
    margin-top: 1rem;

  }

  .timeline-box{
    width: 24rem !important;
  }

  .timeline-vertical{
    margin-left:-24rem;
  }
  }

.div-btn-hero{
  display:flex; 
  gap: 1rem; 
  justify-content: center;
}

.div-btn-hero a{
  color: #FAFFFA;
}

li a{
  font-size:  1rem !important;
}
.form-control span{
  color: white !important;
  background-color: transparent !important;
}
.form-control input, label, textarea, select{
  background: #C9E8FF!important;
  border-color: #C9E8FF !important;
}
.contacto-bg{
  background-color: #02315C;
}
h1{
  margin-left: 1rem;
}
.card{
  width: 96%;
}

.card2 img{
  width: 23rem !important;
  max-width: 28rem !important;
  height: auto !important;
}

.card img{
  width: 100% ;
  height: 22rem;
}

.piel2{
  font-size: 1.2rem;
  color: #E2E6E9;
  
  
}
.card h2{
  
  min-width: min(100%, 10em) !important;
  font-size: 1.7rem !important;
  
}
.card p{
  color: #E2E6E9;
  min-width: min(100%, 10em) !important;
  font-size: 1.2rem !important;
}
.card{
  color: #3B4540 !important;
  background: #071D42 ;
  
}

.timeline-h1{
  font-weight: bold !important;
  min-width: min(100%, 10em) !important;
  font-size: 1.7rem !important;
}

.timeline-p{
  
  min-width: min(100%, 10em) !important;
  font-size: 1.2rem !important;
}

.hr-horizontal-right{
  background-color: #2D533C;
  height: 1px;
  
  margin-right: 50%;
}
.hr-horizontal-left{
  background-color: #2D533C;
  height: 1px;
  
  margin-left: 50%;
}
.timeline-box{
  width: 30rem ;
  background: none;
    border: none;
    box-shadow: none;

}
.timeline-vertical > li > hr {
  width: 0.15rem !important;
}

.timeline-vertical > li > hr{
  background-color: #2D533C !important;
}

.timeline-middle {
  color: white;
  background-color: #2D533C !important;
  border-radius: 0.35rem;
  font-size: 25px;
  height: 41px;
  text-align: center;
  width: 41px;
  background-color: beige;
}

.timeline-start{
  text-align: right;
}



.btn-contacto{
  
  background-color: #000000bf !important;
  border-radius: 0.5rem !important; 
  border: 2px solid #7ff1ff;
  box-shadow: inset 0 0 0 max(1px, var(--shape-border-width)) var(--button-color);
  background-color: var(--shape-bg-color-override, var(--card-color));
  border-radius: var(--button-border-radius, var(--box-border-radius));
}
.verdesito{
  color: #303436 !important;
}
.piel{
  color: #7ff1ff;
}
.verde{
  color: #7ff1ff;
}
.btn-primary{
  border-color: #005798;
  background-color: #005798 !important;
}
html {
  scroll-behavior: smooth !important;
}
.escribenos{
  color: white;
  margin-top: 1rem;
  margin-bottom: -2rem;
}
.separador{
  margin: 0 8rem;
}
.titulo-contacto{
  text-align: center;
  margin-top: 4rem;
  font-size: 4rem;
}

.fade-in-on-scroll {
  opacity: 0;
  transform: translateY(20px);
  transition: opacity 0.6s ease-out, transform 0.6s ease-out;
}

.fade-in-on-scroll.visible {
  opacity: 1;
  transform: translateY(0);
}



















.h1-bottom {
  margin-bottom: 4rem;
}



.p-1-5{
  font-size: 1.5rem;
}
.text-2xl{
  font-size: 2.1rem !important;
  line-height: 3rem !important;
}
.iconos{
  justify-self: center;
  width: 90%;
  height: auto;
}
.fondo-icon{
  margin-top: 10px;
  margin-right: 13px;
  border-radius: 12px;
  background-color: #ffffffe6;
  width:100px !important;
}

.icon-text{
  display: flex;
  align-items: center;
  margin-top: 2rem;
    
}

/* Solid border */
hr.solid {
  border-top: 3px solid #bbb;
}

.beneficios{
  position: relative;
    top: 0rem;
}

.display-serv{
  display: flex;
  justify-content: center;
}

.heros > * {
  text-align: center;
  
}


.cont-l{
  left:2rem !important;
}
.bus-r{
  width: 30rem !important;
}

.sep{
  gap: 9rem !important;
}
.fondito{
  background-color: #1a1d2273;
  
}

.fondito2{
  background-color: #1a1d229c;
}
.background-i {
  
  /* background-image: url('/public/img/2.webp'); */
  background-size: cover; /* Asegura que la imagen cubra todo el contenedor */
  background-position: center; /* Centra la imagen */
  background-repeat: no-repeat; /* Evita que la imagen se repita */
  height: 100vh; /* La altura será igual a la altura de la ventana del navegador */
  display: flex; /* Centra el contenido */
  justify-content: center;
  align-items: center;
  color: white;
  text-shadow: 2px 2px 4px rgba(0, 0, 0, 0.7);
}


.background-2 {
  
  /* background-image: url('/public/img/4-1.jpg'); */
  background-size: cover; /* Asegura que la imagen cubra todo el contenedor */
  background-position: center; /* Centra la imagen */
  background-repeat: no-repeat; /* Evita que la imagen se repita */
  height: 100vh; /* La altura será igual a la altura de la ventana del navegador */
  display: flex; /* Centra el contenido */
  justify-content: center;
  align-items: center;
  color: white;
  text-shadow: 2px 2px 4px rgba(0, 0, 0, 0.7);
}




.top-pegado{
  position: fixed;
  z-index: 2;
}
body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}
